import { Skeleton } from '@mui/material'

const GraphSkeleton = () => {
  return (
    <Skeleton
      height={'100%'}
      variant="rectangular"
      sx={{ borderRadius: 8 }}
    />
  )
}

export default GraphSkeleton