import { Card, CardContent, useTheme } from "@mui/material";
import { CommonComponents } from "common";

const FinancialsSummaryLegend = () => {
  const theme = useTheme();

  const paidColour = theme.palette.primary.main;
  const allocatedColour = "#CB5800";
  const dueColour = "#FD8E39";
  const committedColour = "#FFBA85";
  const balanceColour = "#E3E6E8";
  const budgetTrackingColour = "#53595F";
  const estimatedBudgetColour = "#0085FF";

  return (
    <Card sx={{ height: "100%" }}>
      <CardContent
        sx={{ 
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          height: "64px",
          "&:last-child": {
            pb: "16px"
          }
        }}
      >
        <CommonComponents.LegendItem
          label="Paid"
          color={paidColour}
        />
        <CommonComponents.LegendItem
          label="Allocated"
          color={allocatedColour}
        />
        <CommonComponents.LegendItem
          label="Due"
          color={dueColour}
        />
        <CommonComponents.LegendItem
          label="Committed"
          color={committedColour}
        />
        <CommonComponents.LegendItem
          label="Remaining"
          color={balanceColour}
        />
        <CommonComponents.LegendItem
          label="Spend"
          color={budgetTrackingColour}
        />
        <CommonComponents.LegendItem
          label="Budget"
          color={estimatedBudgetColour}
        />
      </CardContent>
    </Card>
  )
}
export default FinancialsSummaryLegend;