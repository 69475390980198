import { CoreState, useMode } from "@build-buddy/core";
import { Box } from "@mui/material";
import { CommonComponents } from "common";
import { FinancialsBarGraph, FinancialsLineGraph } from "common/components";
import { useSelector } from "common/state/store";
import { useMemo } from "react";
import { useFinancialRoutesParams } from "../FinancialsRoutes";
import FinancialsSummaryBudget from "./financials-summary-budget/FinancialsSummaryBudget";
import FinancialsSummaryCompleted from "./financials-summary-completed/FinancialsSummaryCompleted";
import FinancialsSummaryLegend from "./financials-summary-legend/FinancialsSummaryLegend";
import { format } from "date-fns";

const FinancialsSummary = () => {
  const mode = useMode();
  const { projectId } = useFinancialRoutesParams();
  const projectFinancialsByProjectParams = { projectId };
  const spendingTrendByProjectParams = { projectId };
  const spendingTotalsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);
  const spendingTrendByProjectQuery = CoreState.Financial.getSpendingTrendByProject.useQuery(spendingTrendByProjectParams);
  const spendingTotalsByProjectQuery = CoreState.Financial.getSpendingTotalsByProject.useQuery(spendingTotalsByProjectParams);

  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));
  const spendingTrend = useSelector(s => CoreState.Financial.selectSpendingTrendByProject(s, spendingTrendByProjectParams));
  const spendingTotals = useSelector(s => CoreState.Financial.selectSpendingTotalsByProject(s, spendingTotalsByProjectParams));

  const barData = useMemo(() => {
    if (!spendingTrend) return;
    const mapData = (d: any) => {
      const committed: number[] = [];
      const allocated: number[] = [];
      const paid: number[] = [];
      const projected: number[] = [];
      const label: string[] = [];
      d.forEach((x: any, i: number) => {
        committed.push(x.committed);
        allocated.push(x.allocated);
        paid.push(x.paid);
        projected.push(x.projected);
        const year = format(new Date(x.year, 0), 'yy')
        const lb = x.forthnight ?
          `${x.forthnight}/${year}` :
          x.quarter ?
            `Q${x.quarter} ${year}` :
            `${x.month}-${year}`;
        label.push(lb);
      })
      return { committed, allocated, paid, projected, label };
    }
    const obj: any = {};
    const monthlyLabel = mode.xl ? 'Monthly' : 'M'
    const quarterlyLabel = mode.xl ? 'Quarterly' : 'QTR'
    const forthnightlyLabel = mode.xl ? 'Fortnightly' : 'FN'
    obj[quarterlyLabel] = mapData(spendingTrend.quarterly);
    obj[monthlyLabel] = mapData(spendingTrend.monthly);
    obj[forthnightlyLabel] = mapData(spendingTrend.fortnightly);
    return obj;
  }, [spendingTrend, mode]);

  const lineData = useMemo(() => {
    if (!spendingTotals) return;
    const estimated: number[] = [];
    const forecast: number[] = [];
    const labels: string[] = [];
    spendingTotals.totals.forEach(x => {
      const year = format(new Date(x.year, 0), 'yy')
      estimated.push(x.estimated);
      forecast.push(x.forecasted);
      labels.push(`${x.month}-${year}`);
    })
    return { estimated, forecast, labels }
  }, [spendingTotals])

  return (
    <Box
      sx={{
        display: "grid",
        gap: 2,
        gridTemplateColumns: `40% minmax(250px, 20%) auto auto minmax(250px, 20%)`,
        gridTemplateRows: `64px 75px 190px auto`,
        gridTemplateAreas: `
          'budget legend legend legend legend'
          'budget piechart piechart piechart completed'
          'budget piechart piechart piechart savings'
          'budget graph1 graph1 graph2 graph2'
        `
      }}
    >
      <Box
        sx={{
          gridArea: "budget"
        }}
      >
        <FinancialsSummaryBudget />
      </Box>
      <Box
        sx={{
          gridArea: "legend",
        }}
      >
        <FinancialsSummaryLegend />
      </Box>
      <Box
        sx={{
          gridArea: "piechart",
        }}
      >
        <CommonComponents.CostsPieCard
          sx={{ height: "100%" }}
          isLoading={isLoading}
          data={{
            paid: projectFinancials?.paid || 0,
            allocated: projectFinancials?.allocated || 0,
            balance: projectFinancials?.balance || 0,
            due: projectFinancials?.due || 0,
            committed: projectFinancials?.committed || 0,
            forecast: projectFinancials?.forecast || 0,
          }}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          CostsPieLegendProps={{ hideLabels: true }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "completed",
          position: 'relative'
        }}
      >
        <FinancialsSummaryCompleted />
      </Box>
      <Box
        sx={{
          gridArea: "savings"
        }}
      >
        <CommonComponents.SavingsCard
          isLoading={isLoading}
          savings={projectFinancials?.savings || 0}
          hasBoq={Boolean(projectFinancials?.hasBoq)}
          sx={{ height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          gridArea: "graph1",
        }}
      >
        <FinancialsBarGraph
          data={barData}
          isLoading={spendingTrendByProjectQuery.isLoading}
        />
      </Box>
      <Box
        sx={{
          gridArea: "graph2",
        }}
      >
        <FinancialsLineGraph
          data={lineData as any}
          isLoading={spendingTotalsByProjectQuery.isLoading}
        />
      </Box>
    </Box>

  )
}
export default FinancialsSummary;