import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core"
import { Box, Collapse, Fade, useTheme } from "@mui/material"
import qs from "qs"
import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import BudgetItem from "../budget-item/BudgetItem"
import BudgetItemListEstimated from "./budget-item-list-estimated/BudgetItemListEstimated"
import BudgetItemListTracking from "./budget-item-list-tracking/BudgetItemListTracking"
import { BudgetItemListMode } from "./BudgetItemList"

interface BudgetItemListInnerProps {
  defaultMode: BudgetItemListMode;
  costCentreSummaryList?: Array<CoreState.Financial.CostCentreSummary>;
  filters: any;
}
const BudgetItemListInner = (props: BudgetItemListInnerProps) => {
  //props
  const { defaultMode, costCentreSummaryList, filters } = props;

  // hooks
  const theme = useTheme();
  const routing = CoreRouting.useRouting();
  const { search, pathname } = useLocation();

  // locals
  const refs = useRef<any>({});
  const [mode, setMode] = useState<Record<string, BudgetItemListMode>>({});
  const { code } = qs.parse(search, { ignoreQueryPrefix: true }) as { code?: string }

  // events
  const scrollIntoView = (code: string) => {
    const element = refs.current[code.split(",").filter(Boolean).pop() || ""];
    element?.scrollIntoView({ behavior: "smooth" });
  }

  const handleShow = (c: string) => {
    const codesSet = new Set(code?.split(","));
    if (codesSet.has(c)) {
      codesSet.delete(c)
    }
    else {
      scrollIntoView(c)
      codesSet.add(c)
    }
    routing.go(pathname, { persistSearch: true, filters: [{ "code": Array.from(codesSet) }] })
  }

  const handleSwitch = (code: string, e: any) => {
    const mode = Boolean(e.target.checked) ? BudgetItemListMode.Estimate : BudgetItemListMode.Tracking;
    setMode(prevMode => ({ ...prevMode, [code]: mode }));
  }

  const getMode = (c: string) => {
    // finds the mode of the switch, otherwise uses the default mode provided
    return mode[c] || defaultMode;
  }

  const getExpanded = (c: string) => {
    // if c is inside the code query string, then its expanded
    return code?.includes(c)
  }

  useEffect(() => {
    // if no items or the code is not a string, then exit
    if (!costCentreSummaryList?.length || typeof code !== "string") return;
    // using the code, find the last element in the array and use the ref to scroll into view
    scrollIntoView(code);
  }, [costCentreSummaryList?.length]);

  return (
    <>
      {costCentreSummaryList?.map((costCentreSummary, i) =>
        <Box
          key={i}
          sx={{
            scrollMarginTop: "180px",
          }}
          ref={(el: any) => refs.current[costCentreSummary.costCentre.code] = el}
        >
          <BudgetItem
            code={costCentreSummary.costCentre.code}
            name={costCentreSummary.costCentre.name}
            allocated={costCentreSummary.summary.allocated}
            accepted={costCentreSummary.summary.accepted}
            paid={costCentreSummary.summary.paid}
            budget={costCentreSummary.summary.budget}
            overBudget={costCentreSummary.summary.overBudget}
            projectedOverBudget={costCentreSummary.summary.projectedOverBudget}
            savings={costCentreSummary.summary.savings}
            projectedSavings={costCentreSummary.summary.projectedSavings}
            IconProps={{
              sx: {
                transform: `rotate(${getExpanded(costCentreSummary.costCentre.code) ? "90deg" : "0"})`
              }
            }}
            actions={getExpanded(costCentreSummary.costCentre.code) &&
              <Fade in={true}>
                <Box sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  position: "relative",
                  top: 2
                }}>
                  <CoreComponents.Switch
                    name="switch"
                    checked={(getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Estimate)}
                    innerlabels={["Spend", "Budget"]}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleSwitch(costCentreSummary.costCentre.code, e)}
                    sx={{
                      flex: 1,
                      maxWidth: "200px",
                      background: theme.palette.grey[600],
                      textAlign: "center"
                    }}
                  />
                </Box>
              </Fade>
            }
            onClick={() => handleShow(costCentreSummary.costCentre.code)}
            sx={{
              mx: -2,
              px: 2,
              py: 1.5,
              borderBottom: `solid 1px ${theme.palette.grey[200]}`,
              background: theme.palette.grey[50],
            }}
          />
          <Collapse
            in={getExpanded(costCentreSummary.costCentre.code)}
            unmountOnExit={true}
          >
            {getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Tracking &&
              <BudgetItemListTracking
                costCentreCode={costCentreSummary.costCentre.code}
                filters={filters}
              />
            }
            {getMode(costCentreSummary.costCentre.code) === BudgetItemListMode.Estimate &&
              <BudgetItemListEstimated
                costCentreCode={costCentreSummary.costCentre.code}
                filters={filters}
              />
            }
          </Collapse>
        </Box>
      )}
    </>
  )
}

export default BudgetItemListInner;