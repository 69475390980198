import {
  CoreRouting,
  CoreState
} from '@build-buddy/core';
import { CommonComponents } from 'common';
import { useTaskViewParams } from '../../_hooks';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';
import ExpenseActions from './TaskViewExpenseActions';

const TaskViewExpenses = () => {
  // hooks
  const routing = CoreRouting.useRouting();
  const taskViewParams = useTaskViewParams();

  const expenseListParams = {
    projectId: taskViewParams.projectId,
    taskId: taskViewParams.taskId,
    stageId: taskViewParams.stageId,
  }

  // handlers
  const handleAddClick = () => {
    routing.go(TaskViewPaymentsRouteDefinitions.ADD_EXPENSE)
  }

  const handleExpenseClick = (expense: CoreState.Financial.Expense) => {
    const params = {
      psid: expense.id
    }
    routing.go(TaskViewPaymentsRouteDefinitions.VIEW_EXPENSE, { params })
  }

  return (
    <CommonComponents.ExpenseList
      params={expenseListParams}
      onExpenseClick={handleExpenseClick}
      onExpenseAdd={handleAddClick}
      ActionsComponent={ExpenseActions}
    />
  )
}

export default TaskViewExpenses