import { CoreHooks } from "@build-buddy/core";

const useCostsPieChart = () => {
  const mode = CoreHooks.useMode();
  const large = {
    height: 250,
    hole: 100,
    price: 24,
    forecast: 18
  };

  const medium = {
    height: 250,
    hole: 80,
    price: 20,
    forecast: 16
  };

  const small = {
    height: 175,
    hole: 65,
    price: 17,
    forecast: 13
  }
  if (mode.xl) return large;
  if (mode.md || mode.lg) return medium;
  if (mode.sm) return small;
  return small
}

export default useCostsPieChart;