import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon, RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { useEffect, useState } from "react";
import TaskViewPaymentsRouteDefinitions from "../TaskViewPaymentsRouteDefinitions";
import { useTaskViewParams } from "../../_hooks";

type TaskViewExpenseActionsProps = {
  expense: CoreState.Financial.Expense;
}

const TaskViewExpenseActions = ({ expense }: TaskViewExpenseActionsProps) => {
  const routing = CoreRouting.useRouting();
  const taskViewParams = useTaskViewParams();

  // states
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const handleViewClick = () => {
    const params = {
      psid: expense.id
    }
    routing.go(TaskViewPaymentsRouteDefinitions.VIEW_EXPENSE, { params });
  }

  const handleEditClick = () => {
    const params = {
      psid: expense.id
    }
    routing.go(TaskViewPaymentsRouteDefinitions.EDIT_EXPENSE, { params });
  }

  const handleDeleteClick = () => {
    setShowConfirmationModal(true);
  }

  // Mutation
  const [deleteExpenseAction, deleteExpenseQuery] = CoreState.Financial.deleteExpense.useMutation();

  // handle delete
  const deleteExpense = () => {
    deleteExpenseAction({
      projectId: taskViewParams.projectId,
      expenseId: expense.id
    })
  }

  useEffect(() => {
    if (deleteExpenseQuery.isSuccess) setShowConfirmationModal(false);
  }, [deleteExpenseQuery.isSuccess])

  return (
    <>
      <CoreComponents.Loader show={false} />
      <CoreComponents.TableButton
        responsive={false}
        buttons={[
          {
            variant: "contained",
            label: 'View',
            icon: RemoveRedEyeIcon,
            onClick: handleViewClick
          },
          {
            variant: "contained",
            label: 'Edit',
            icon: EditIcon,
            onClick: handleEditClick
          },
          {
            variant: "contained",
            label: 'Delete',
            icon: DeleteOutlineIcon,
            onClick: handleDeleteClick
          },
        ]}
      />
      <CoreComponents.Confirmation
        open={showConfirmationModal}
        title="Delete Expense"
        text="Are you sure you want to delete?"
        onClose={() => setShowConfirmationModal(false)}
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={deleteExpense}
        cancelText="Cancel"
        confirmText="Delete"
        loading={deleteExpenseQuery.isLoading}
      />
    </>
  )
}

export default TaskViewExpenseActions;