import { CoreComponents, useMode } from "@build-buddy/core";
import { Box, Card, CardContent, Popper, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { BarChartPro, ChartsReferenceLine, PopperProps } from "@mui/x-charts-pro";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import GraphSkeleton from "./GraphSkeleton";
import { findCurrentQuarter, getCurrentFortnightNumber, toolTipValueFormatter } from "./utils/utils";

interface BarGraphItems {
  committed: number[],
  allocated: number[],
  paid: number[],
  projected: number[],
  label: string[]
}

interface FinancialsBarGraphProps {
  data: {
    'M': BarGraphItems,
    'QTR': BarGraphItems,
    'FN': BarGraphItems
  } |
  {
    'Monthly': BarGraphItems,
    'Quarterly': BarGraphItems,
    'Fortnightly': BarGraphItems
  },
  isLoading: boolean;
}

const FinancialsBarGraph = (props: FinancialsBarGraphProps) => {
  const mode = useMode()
  const [currentTab, setCurrentTab] = useState<{ label: keyof typeof props.data }>()
  const [currentDataset, setCurrentDataset] = useState<any>()
  const [currentRoutes, setCurrentRoutes] = useState<{ label: keyof typeof props.data }[]>()

  // Locals
  const monthlyLabels = ['M', 'Monthly']
  const quarterlyLabels = ['QTR', 'Quarterly']
  const fortnightlyLabels = ['FN', 'Fortnightly']
  const currentTabLabel = currentTab?.label || ''
  const isMonthly = monthlyLabels.includes(currentTabLabel)
  const isQuarterly = quarterlyLabels.includes(currentTabLabel)
  const isFortnightly = fortnightlyLabels.includes(currentTabLabel)

  // This determines the size of the bars.
  const categoryGapRatio = mode.xl ?
    isMonthly ? 0.7 : isQuarterly ? 0.8 : 0.6 :
    isMonthly ? 0.75 : isQuarterly ? 0.7 : 0.65;

  // Today: Format
  const currentDate = format(new Date(), 'MMM-yy')
  const currentYear = format(new Date(), 'yy')

  // Calculate logic for lables to set a marker.
  const currentMonthMarker = currentDate
  const currentQuarterMarker = `${findCurrentQuarter(currentDate)} ${currentYear}`
  const currentFortnightMarker = `${getCurrentFortnightNumber()}/${currentYear}`

  // Load marker based on the current data set type.
  const marker = isMonthly ?
  currentMonthMarker : isFortnightly ?
      currentFortnightMarker : currentQuarterMarker
  const widthOfGraph = isMonthly ? 900 : isFortnightly ? 1200 : undefined

  // Data and labels.
  useEffect(() => {
    if (!props.data) return
    const frequency = Object.keys(props.data) as Array<keyof typeof props.data>;
    const routes = frequency.map(f => ({ label: f }));
    setCurrentRoutes(routes)
    setCurrentTab(routes[1])
  }, [props.data])

  useEffect(() => {
    if (!props.data) return
    const currentDataset = props.data[currentTab?.label as keyof typeof props.data]
    setCurrentDataset(currentDataset)
  }, [currentTab])

  // Scroll to today.
  useEffect(() => {
    const timer = setTimeout(() => {
      const marker = document.querySelectorAll('.MuiChartsReferenceLine-label');
      if (!marker) {
        return;
      }
      marker[0]?.scrollIntoView({ behavior: 'smooth' });
    }, 300);

    return () => clearTimeout(timer);
  }, [props.data, currentTab]);

  return (
    <CoreComponents.PreContent
      isLoading={props.isLoading}
      loader={<GraphSkeleton />}
      isEmpty={false}
    >
      <Card>
        <CardContent sx={{ px: 0, py: '0px !important' }}>
          <Stack direction='row' alignItems='center' gap={3} px={2} width={'100%'} >
            <Typography fontSize={12} fontWeight={600}>Spend</Typography>
            {currentRoutes &&
              <CoreComponents.Tabs
                TabsProps={{
                  sx: {
                    mb: 1,
                    "& .MuiTab-root": {
                      minWidth: '60px !important'
                    }
                  }
                }}
                type="component"
                defaultRoute={currentTab}
                TabItemProps={{
                  sx: {
                    fontSize: '12px',
                    textTransform: 'capitalize',
                  }
                }}
                routes={currentRoutes as any}
                onChange={(v) => setCurrentTab(currentRoutes[v])}
              />}
          </Stack>
          <Box
            sx={{
              height: "300px",
              width: '100%',
              overflowX: 'scroll',
              overflowY: 'hidden',
              aspectRatio: "1/1"
            }}
          >
            {currentDataset &&
              <BarChartPro
                grid={{ horizontal: true, vertical: false }}
                height={300}
                width={widthOfGraph}
                xAxis={[
                  {
                    scaleType: 'band',
                    dataKey: 'month',
                    categoryGapRatio,
                    data: currentDataset?.label,
                    zoom: true,
                    minStart: 100,
                    disableTicks: true,
                    disableLine: true,
                  } as any,
                ]}
                series={[
                  { data: currentDataset?.committed, type: 'bar', color: '#FFB882', stack: 'total', valueFormatter: (v) => toolTipValueFormatter(v) },
                  { data: currentDataset?.allocated, type: 'bar', color: '#CB5800', stack: 'total', valueFormatter: (v) => toolTipValueFormatter(v) },
                  { data: currentDataset?.paid, type: 'bar', color: '#FF6F00', stack: 'total', valueFormatter: (v) => toolTipValueFormatter(v) },
                  { data: currentDataset?.projected, type: 'bar', color: '#E3E6E8', stack: 'total', valueFormatter: (v) => toolTipValueFormatter(v) },
                ]}
                borderRadius={12}
                slots={{
                  popper: CustomPopperRoot,
                }}
                leftAxis={null}
              >
                <ChartsReferenceLine
                  x={`${marker}`}
                  lineStyle={{ stroke: '#eee' }}
                  label={`Scaled Label`}
                  labelStyle={{ fill: 'transparent' }}
                />
              </BarChartPro>
            }
          </Box>
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}

// Custom Tooltip for Graph
const ChartsTooltipRoot = styled(Popper, {
  name: "MuiChartsTooltip",
  slot: "Root",
  overridesResolver: (_, styles) => styles.root,
})(({ theme }) => ({
  pointerEvents: "none",
  zIndex: theme.zIndex.modal,
  "& .MuiChartsTooltip-paper": {
    background: 'rgba(0, 0, 0, .3)',
    padding: '4px 8px'
  },
  'thead': {
    "tr": {
      "td": {
        padding: '0px !important',
        "p": {
          color: 'white'
        }
      },
      margin: '8px !important'
    },
  },
  "& .MuiChartsTooltip-markCell": {
    padding: '3px 3px 0px 3px !important',
    "& .MuiChartsTooltip-mark": {
      border: 'none',
      height: '12px',
      width: '12px'
    }
  },
  '& .MuiChartsTooltip-valueCell': {
    padding: '3px 3px 0px 3px !important',
    "& .MuiTypography-body1": {
      color: 'white',
      fontWeight: '500'
    }
  }
}));

const CustomPopperRoot = (props: PopperProps) => {
  return (
    <ChartsTooltipRoot
      {...props}
      anchorEl={{
        getBoundingClientRect: () => ({
          ...(props.anchorEl as any)?.getBoundingClientRect(),
        }),
      }}
    />
  );
};

export default FinancialsBarGraph
