import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { useSelector } from 'common/state/store';
import { selectTaskViewQuoteListAcceptedQuoteQuoteId } from 'modules/project/task/task-view/_selectors';
import { useEffect } from 'react';
import { useBudgetExpenseParams } from './BudgetExpenseRoutes';

const BudgetTrackingExpenseAdd = () => {
  // hooks
  const { download } = CoreState.File.useDownloadQueue();
  const routing = CoreRouting.useRouting();
  const budgetTrackingExpenseParams = useBudgetExpenseParams();

  // params
  const params = {
    projectId: budgetTrackingExpenseParams.projectId,
    stageId: budgetTrackingExpenseParams.stageId,
    taskId: budgetTrackingExpenseParams.taskId,
  }

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(params);

  // mutations
  const [addExpenseAction, addExpenseQuery] = CoreState.Financial.addExpense.useMutation();

  // locals
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));
  const latestQuoteRequest = useSelector((s) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(s, params));

  const handleClose = () => routing.back({ persistSearch: true });

  const handleAddExpense = async (values: any) => {
    await addExpenseAction({
      projectId: params.projectId,
      workOrderId: quoteId || undefined,
      taskId: params.taskId,
      expense: values.expense
    })
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    const downloadParams = {
      projectId: params.projectId,
      paymentScheduleId: budgetTrackingExpenseParams.paymentScheduleId,
      documentId: doc.id ?? ""
    }
    download(doc, CoreState.File.downloadDocByExpense.initiate(downloadParams));
  };

  useEffect(() => {
    if (addExpenseQuery.isSuccess) handleClose()
  }, [addExpenseQuery.isSuccess])


  return (
    <CoreComponents.ExpenseFormDialog
      title="Add Expense"
      onClose={handleClose}
      onSubmit={handleAddExpense}
      onDownload={handleDownload}
      isSaving={addExpenseQuery.isLoading}
      isLoading={quoteListQuery.isLoading}
    />
  )
}

export default BudgetTrackingExpenseAdd