import { CoreComponents, CoreRouting, CoreState } from '@build-buddy/core';
import { useSelector } from 'common/state/store';
import { useEffect } from 'react';
import { useTaskViewParams } from '../../_hooks';
import { selectTaskViewQuoteListAcceptedQuoteQuoteId } from '../../_selectors';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';

const TaskViewExpenseAdd = () => {
  // hooks
  const { download } = CoreState.File.useDownloadQueue();
  const taskViewParams = useTaskViewParams();
  const routing = CoreRouting.useRouting();

  const params = {
    projectId: taskViewParams.projectId,
    stageId: taskViewParams.stageId,
    taskId: taskViewParams.taskId,
  }

  // queries
  const quoteListQuery = CoreState.Quote.getQuoteListByLatestQuoteRequest.useQuery(params);

  // Selector
  const quoteId = useSelector((s) => selectTaskViewQuoteListAcceptedQuoteQuoteId(s, params));
  const latestQuoteRequest = useSelector((s) => CoreState.Quote.selectQuoteRequestListByTaskLatestQuoteRequest(s, params));

  // Mutation
  const [addExpenseAction, addExpenseQuery] = CoreState.Financial.addExpense.useMutation();

  const handleClose = () => {
    const params = {
      sid: taskViewParams.stageId,
      tid: taskViewParams.taskId
    };
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE, { params })
  }

  const handleAddExpense = async (values: any) => {
    await addExpenseAction({
      projectId: taskViewParams.projectId,
      workOrderId: quoteId || undefined,
      taskId: taskViewParams.taskId,
      expense: values.expense
    })
  }

  const handleDownload = (doc: CoreState.File.Doc) => {
    const params = {
      projectId: taskViewParams.projectId,
      paymentScheduleId: taskViewParams.paymentScheduleId,
      documentId: doc.id ?? ""
    }
    download(doc, CoreState.File.downloadDocByExpense.initiate(params));
  };

  useEffect(() => {
    if (addExpenseQuery.isSuccess) handleClose()
  }, [addExpenseQuery.isSuccess])


  return (
    <CoreComponents.ExpenseFormDialog
      title="Add Expense"
      onClose={handleClose}
      onSubmit={handleAddExpense}
      onDownload={handleDownload}
      isSaving={addExpenseQuery.isLoading}
      isLoading={quoteListQuery.isLoading}
    />
  )
}

export default TaskViewExpenseAdd