import { CoreComponents, CoreRouting, CoreState } from "@build-buddy/core";
import { useTheme } from "@mui/material";
import { useSelector } from "common/state/store";
import { useEffect } from "react";
import { FinancialRoutesDefinition, useFinancialRoutesParams } from "./FinancialsRoutes";
import FinancialsSkeleton from "./FinancialsSkeleton";

const Financials = () => {
  const theme = useTheme();
  const routing = CoreRouting.useRouting();

  // params
  const { projectId } = useFinancialRoutesParams();
  const getProjectParams = { projectId };

  // queries
  const getProjectQuery = CoreState.Project.getProject.useQuery(getProjectParams, { skip: !projectId });

  // locals
  const isLoading = Boolean(getProjectQuery.isLoading);
  const project = useSelector(s => CoreState.Project.selectProject(s, getProjectParams));

  useEffect(() => {
    if (!project) return;
    if (project.budgetLocked) return;
    const params = { pid: projectId };
    routing.go(FinancialRoutesDefinition.UNLOCKED_BUDGET, { params });
  }, [project])

  // TODO: this should probably be done in the initialise to prevent the "waterfall routes"
  if (isLoading) return <FinancialsSkeleton />

  return (
    <CoreComponents.Tabs
      disabled={false}
      TabsProps={{
        sx: {          
          mx: -3,
          mt: -2,
          px: 1,
          position: "sticky",
          top: 64,
          zIndex: theme.zIndex.sticky,
          background: theme.palette.common.white,
        }
      }}
      routes={[
        FinancialRoutesDefinition.FINANCIALS_SUMMARY,
        FinancialRoutesDefinition.BUDGET_TRACKING,
        FinancialRoutesDefinition.ESTIMATED_BUDGET,
        // FinancialRoutesDefinition.FINANCE
      ]}
    />
  )
}
export default Financials;