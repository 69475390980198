import { CoreUtils } from "@build-buddy/core";

export const findCurrentQuarter = (currentMonth:string)=> Math.floor(((new Date(currentMonth).getMonth() + 9) % 12) / 3) + 1

export const getCurrentFortnightNumber = () => {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1); // January 1 of the current year

  // Calculate the difference in time (in milliseconds)
  const diffTime = +today - +startOfYear;

  // Calculate the difference in days
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  // Calculate the fortnight number
  const fortnightNumber = Math.floor(diffDays / 14) + 1; // +1 to start counting from 1

  return fortnightNumber;
}

export const toolTipValueFormatter = (v: number | null):any => {
  if (v === null) return null;
  return CoreUtils.Formatter.currency(v)
}