import { CoreModules, CoreRouting, CoreState } from '@build-buddy/core';
import { useSelector } from 'react-redux';
import { useTaskViewParams } from '../../_hooks';
import TaskViewPaymentsRouteDefinitions from '../TaskViewPaymentsRouteDefinitions';

const TaskViewExpenseView = () => {
  const routing = CoreRouting.useRouting();
  const taskViewParams = useTaskViewParams();

  // params
  const params = {
    projectId: taskViewParams.projectId,
    stageId: taskViewParams.stageId,
    taskId: taskViewParams.taskId
  }

  // Selectors
  const expense = useSelector((state) => CoreState.Financial.selectAcceptedQuoteOrTaskExpenseById(state, params, { expenseId: taskViewParams.paymentScheduleId }))

  // Event handlers
  const handleClose = () => {
    const params = {
      sid: taskViewParams.stageId,
      tid: taskViewParams.taskId
    };
    routing.go(TaskViewPaymentsRouteDefinitions.TASK_VIEW_PAYMENT_SCHEDULE, { params })
  }

  return (
    <CoreModules.Financials.Expenses.ExpenseItemView
      onClose={handleClose}
      expense={expense}
    />
  )
}

export default TaskViewExpenseView