import { CoreRouting, CoreState } from "@build-buddy/core";
import { CommonState, RouteDefinitions } from "common";
import { useDispatch, useSelector } from "common/state/store";
import { InvitationRouteDefinitions } from "modules/invitation";
import { WizardRoutesDefinitions } from "modules/wizard/WizardRoutes";
import { useEffect, useMemo } from "react";
import { useRouteMatch } from "react-router";

const ProjectGuard = (props: any) => {
  // hooks  
  const routing = CoreRouting.useRouting();
  const dispatch = useDispatch();
  const paths = useMemo(() => Object.values(RouteDefinitions.Project).map((x) => x.path), []);
  const projectRootMatch = useRouteMatch(RouteDefinitions.Project.PROJECT);
  const projectViewMatch = useRouteMatch<any>(RouteDefinitions.Project.PROJECT_VIEW);
  const currentMatch = useRouteMatch<any>({ path: paths, exact: true });
  const projectId = useSelector(CommonState.App.selectCurrentProjectId);
  const projects = useSelector(CoreState.Project.getUserProjectList.select());
  const p = projectViewMatch?.params?.pid;
  const foundProject = projects.data?.find(x => x.id === p);

  // newish stuff
  const userId = useSelector((s) => CoreState.User.selectCurrentUserId(s));
  const invites = useSelector((s) => CoreState.Membership.selectInviteListByUser(s, { userId }))

  useEffect(() => {
    if (projects.isLoading) return;
    if (!foundProject || foundProject.id === projectId) return;
    dispatch(CommonState.App.switchProject({ project: foundProject }))
  }, [foundProject]);

  // TODO: this is a bit of a mess, but it works for now. We need to refactor the routing logic
  useEffect(() => {
    if (projects.isLoading) return;

    // this should never be the case, but we will add a check just incase
    if (!currentMatch) return;

    if (!projectId && !foundProject && invites.length) return routing.go(InvitationRouteDefinitions.INVITATION_INITIAL);

    if (!projectId && !foundProject) return routing.go(WizardRoutesDefinitions.WIZARD_CREATE)

    // if we are on a route with no component, redirect to dashboard
    const params = { pid: projectId }
    if (projectRootMatch?.isExact || projectViewMatch?.isExact) {
      return routing.go(RouteDefinitions.Project.DASHBOARD, { params })
    }

    if(currentMatch?.isExact){
      const params = { ...currentMatch.params, pid: projectId };
      routing.go(currentMatch.path, { params });
    }

  }, [projectId])

  return props.children
}

export default CoreRouting.withGuard(ProjectGuard);