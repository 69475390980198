import { CoreComponents, CoreState } from "@build-buddy/core";
import { Box } from "@mui/material";
import { useSelector } from "common/state/store";
import { FinancialRoutesDefinition, useFinancialRoutesParams } from "../FinancialsRoutes";
import UnlockedBudgetBanner from "./unlocked-budget-banner/UnlockedBudgetBanner";
import UnlockedBudgetDraft from "./unlocked-budget-draft/UnlockedBudgetDraft";
import UnlockedBudgetEmpty from "./UnlockedBudgetEmpty";
import UnlockedBudgetSkeleton from "./UnlockedBudgetSkeleton";

const UnlockedBudget = () => {
  const { projectId } = useFinancialRoutesParams();

  // params
  const filters = { hideZeroTasks: false, hideZeros: true };
  const getBudgetByProjectParams = { projectId, filters };

  // queries
  const getBudgetByProjectQuery = CoreState.Financial.getBudgetByProject.useQuery(getBudgetByProjectParams);

  // locals
  const isLoading = Boolean(!getBudgetByProjectQuery.isUninitialized && getBudgetByProjectQuery.isLoading);
  const budget = useSelector(s => CoreState.Financial.selectBudgetByProject(s, getBudgetByProjectParams));

  // locals
  const isEmpty = !budget || budget.version === CoreState.Financial.BudgetVersion.Unknown;

  return (
    <Box>
      <CoreComponents.Tabs
        disabled={true}
        TabsProps={{
          sx: {
            background: "white",
            mx: -3,
            mt: -2,
            px: 1,
          }
        }}
        routes={[
          FinancialRoutesDefinition.FINANCIALS_SUMMARY,
          FinancialRoutesDefinition.BUDGET_TRACKING,
          FinancialRoutesDefinition.ESTIMATED_BUDGET,
          // FinancialRoutesDefinition.FINANCE
        ]}
      />
      <CoreComponents.PreContent
        isLoading={isLoading}
        loader={<UnlockedBudgetSkeleton />}
        isEmpty={isEmpty}
        empty={<UnlockedBudgetEmpty />}
      >
        <UnlockedBudgetBanner
          budget={budget as CoreState.Financial.Budget}
        />
        <UnlockedBudgetDraft
          budget={budget as CoreState.Financial.Budget}
        />
      </CoreComponents.PreContent>
    </Box >
  )
}
export default UnlockedBudget;