import { CoreModules, CoreRouting, CoreState } from '@build-buddy/core';
import { useSelector } from 'react-redux';
import { useBudgetExpenseParams } from './BudgetExpenseRoutes';

const BudgetTrackingExpenseView = () => {
  const routing = CoreRouting.useRouting();
  const budgetTrackingExpenseParams = useBudgetExpenseParams();

  // params
  const params = {
    projectId: budgetTrackingExpenseParams.projectId,
    stageId: budgetTrackingExpenseParams.stageId,
    taskId: budgetTrackingExpenseParams.taskId
  }

  // Selectors
  const expense = useSelector((state) => CoreState.Financial.selectAcceptedQuoteOrTaskExpenseById(state, params, { expenseId: budgetTrackingExpenseParams.paymentScheduleId }))

  // Event handlers
  const handleClose = () => routing.back({ persistSearch: true });

  return (
    <CoreModules.Financials.Expenses.ExpenseItemView
      onClose={handleClose}
      expense={expense}
    />
  )
}

export default BudgetTrackingExpenseView