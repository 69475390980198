import { ChatRoutes, CoreModules } from "@build-buddy/core";
import { CssBaseline } from "@mui/material";
import "@progress/kendo-theme-default/dist/all.css";
import { useJsApiLoader } from "@react-google-maps/api";
import { RouteDefinitions } from "common";
import { PageTemplate } from "common/components";
import { InvitationRoutes } from "modules/invitation/InvitationRoutes";
import OnboardingModule from "modules/onboarding/OnboardingModule";

import { getConfig } from "common";
import { CompanyRoutes } from "modules/company/CompanyRoutes";
import { ProjectRoutes } from "modules/project/ProjectRoutes";
import { HelpRoutes } from "modules/help/HelpRoutes";
import { UserProfileRoutes } from "modules/user/profile";
import WalletModule from "modules/user/wallet/WalletModule";
import { WizardRoutes } from "modules/wizard/WizardRoutes";
import { FC } from "react";
import { Redirect, Route } from "react-router";
import AppInitialise from "./AppInitialise";
import { OtherRoutes } from "./OtherRoutes";
import { LicenseInfo } from '@mui/x-license';

// Set MUI License Key
LicenseInfo.setLicenseKey(getConfig().muiLicenseKey)

const authRoutes = Object.values(RouteDefinitions)
  .map((x) => Object.values(x))
  .flat()
  .map((x) => x.path);

const App: FC = () => {

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: getConfig().googleApiKey,
    libraries: ["places"],
  });

  if (!isLoaded) return null;

  return (
    <>
        <CssBaseline />
        <Route path="/" exact={true} component={() => <Redirect to="/project" />} />

        {/** Public Routes */}
        <CoreModules.Auth.AuthRoutes />
        <OtherRoutes />

        {/** Private Routes */}
        <CoreModules.Auth.AuthGuard path={authRoutes}>
          <AppInitialise>
            <PageTemplate>
              <OnboardingModule />

              <CoreModules.Legal.LegalRoutes />
              <UserProfileRoutes />
              <ChatRoutes />
              <CompanyRoutes />
              <ProjectRoutes />
              <InvitationRoutes />
              <WalletModule />

              <HelpRoutes />
              <WizardRoutes />
            </PageTemplate>
          </AppInitialise>
        </CoreModules.Auth.AuthGuard>
    </>
  );
};

export default App;
