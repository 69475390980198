import { Stack, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";

const BudgetItemListTrackingPaymentScheduleEmpty = () => {
  return (
    <Stack sx={{ py: 2.5 }} direction="row" alignItems="center">
      <ErrorIcon sx={{ position: "relative", bottom: 1, mr: 0.75 }} />
      <Typography variant="body1">There are no payment schedules</Typography>
    </Stack>
  )
}
export default BudgetItemListTrackingPaymentScheduleEmpty;