import { CommonComponents } from "common";
import { useSelector } from "common/state/store";
import { useProjectParams } from "modules/project/_hooks";
import { CoreState } from "@build-buddy/core";

const DashboardFinancialsPie = () => {
  const { projectId } = useProjectParams();
  const projectFinancialsByProjectParams = { projectId };

  // queries
  const projectFinancialsByProjectQuery = CoreState.Financial.getProjectFinancialsByProject.useQuery(projectFinancialsByProjectParams);

  // locals
  const isLoading = Boolean(!projectFinancialsByProjectQuery.isUninitialized && projectFinancialsByProjectQuery.isLoading);
  const projectFinancials = useSelector(s => CoreState.Financial.selectProjectFinancialsByProject(s, projectFinancialsByProjectParams));

  return (
    <CommonComponents.CostsPieCard
      sx={{ height: "100%" }}
      isLoading={isLoading}
      data={{
        paid: projectFinancials?.paid || 0,
        allocated: projectFinancials?.allocated || 0,
        balance: projectFinancials?.balance || 0,
        due: projectFinancials?.due || 0,
        committed: projectFinancials?.committed || 0,
        forecast: projectFinancials?.forecast || 0,
      }}
      hasBoq={Boolean(projectFinancials?.hasBoq)}
    />
  )
}
export default DashboardFinancialsPie;