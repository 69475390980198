import { Route, Switch } from "react-router-dom";
import { useProjectParams } from "../_hooks";
import { BudgetExpenseRoutes } from "./_components/budget-expense/BudgetExpenseRoutes";
import { BudgetMakePaymentRoutes } from "./_components/budget-make-payment/BudgetMakePaymentRoutes";
import { BudgetReceiptPaymentRoutes } from "./_components/budget-receipt-payment/BudgetReceiptPaymentRoutes";
import { BudgetReleasePaymentRoutes } from "./_components/budget-release-payment/BudgetReleasePaymentRoutes";
import { BudgetVariationRoutes } from "./_components/budget-variation/BudgetVariationRoutes";
import { BudgetTrackingItemRoutes } from "./budget-tracking-item/BudgetTrackingItemRoutes";
import BudgetTracking from "./budget-tracking/BudgetTracking";
import EstimatedBudget from "./estimated-budget/EstimatedBudget";
import Finance from "./finance/Finance";
import Financials from "./Financials";
import FinancialsSummary from "./financials-summary/FinancialsSummary";
import FinancialsGuard from "./FinancialsGuard";
import UnlockedBudget from "./unlocked-budget/UnlockedBudget";

export const useFinancialRoutesParams = () => {
  const { projectId } = useProjectParams();
  return {
    projectId,
    // TODO: this is a depricated param that is still required in selectors. We need to eventually remove this
    stageId: "x",
  }
}

export const FinancialRoutesDefinition = {
  FINANCIALS_ROOT: {
    path: "/project/:pid/financials",
    component: Financials
  },
  UNLOCKED_BUDGET: {
    path: "/project/:pid/financials/unlocked",
    component: UnlockedBudget
  },
  ESTIMATED_BUDGET: {
    label: "Budget",
    path: "/project/:pid/financials/estimated-budget",
    component: EstimatedBudget
  },
  FINANCIALS_SUMMARY: {
    label: "Financial Summary",
    path: "/project/:pid/financials/summary",
    component: FinancialsSummary
  },
  BUDGET_TRACKING: {
    label: "Spend Vs Budget",
    path: "/project/:pid/financials/budget-tracking",
    component: BudgetTracking
  },
  // FINANCE: {
  //   label: "Finance",
  //   path: "/project/:pid/financials/finance",
  //   component: Finance
  // }
};

export const FinancialRoutes = () => {
  return (
    <FinancialsGuard path={[FinancialRoutesDefinition.FINANCIALS_ROOT.path]}>
      <Switch>
        <Route
          path={FinancialRoutesDefinition.UNLOCKED_BUDGET.path}
          component={FinancialRoutesDefinition.UNLOCKED_BUDGET.component}
        />
        <Route
          path={FinancialRoutesDefinition.FINANCIALS_ROOT.path}
          component={FinancialRoutesDefinition.FINANCIALS_ROOT.component}
        />
      </Switch>
      <BudgetMakePaymentRoutes />
      <BudgetReleasePaymentRoutes />
      <BudgetReceiptPaymentRoutes />
      <BudgetExpenseRoutes />
      <BudgetVariationRoutes />
      <BudgetTrackingItemRoutes />
    </FinancialsGuard>
  );
};
