import { CoreComponents, CoreRouting, CoreUtils } from "@build-buddy/core";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import { Box, Stack, useTheme } from "@mui/material";
import { CommonComponents } from "common";
import qs from "qs";
import { useLocation } from "react-router-dom";
import BudgetTrackingTaskExpansion from "./BudgetITemListTrackingTaskExpansion";

interface BudgetTrackingTaskProps {
  taskId: string;
  label: string;
  accepted: number;
  allocated: number;
  paid: number;
  isTaskActive: boolean;
  isDIY: boolean;
}
const BudgetTrackingTask = (props: BudgetTrackingTaskProps) => {
  const { taskId, label, accepted, allocated, paid, isTaskActive, isDIY } = props;
  const theme = useTheme();
  const routing = CoreRouting.useRouting();
  const { search, pathname } = useLocation();
  const { tasks } = qs.parse(search, { ignoreQueryPrefix: true }) as { tasks?: string }

  // events
  const handleShow = () => {
    const codesSet = new Set(tasks?.split(","));
    codesSet.has(taskId) ? codesSet.delete(taskId) : codesSet.add(taskId);
    routing.go(pathname, { persistSearch: true, filters: [{ "tasks": Array.from(codesSet) }] })
  }

  const getExpanded = () => {
    // if c is inside the code query string, then its expanded
    return tasks?.includes(taskId)
  }

  return (
    <>
      <Box
        sx={{
          cursor: "pointer",
          my: 2,
          display: "grid",
          alignItems: "center",
          gridTemplateAreas: `
            'task task cost cost icon'
          `,
          gridTemplateColumns: {
            xs: `75px minmax(120px, 400px) minmax(120px, 275px) minmax(100px, auto) 30px`,
            lg: `75px minmax(120px, 400px) minmax(120px, 300px) minmax(100px, auto) 30px`,
            xl: `75px minmax(120px, 600px) minmax(120px, 300px) minmax(100px, auto) 30px`,
          }
        }}
        onClick={handleShow}
      >
        <Box sx={{ gridArea: "task" }}>
          <Box>
            <CoreComponents.TaskLabel
              sx={{
                mb: 0,
              }}
              label={label}
              isActive={isTaskActive}
              isDIY={isDIY}
            />
          </Box>
        </Box>
        <Stack
          direction="row"
          sx={{
            gridArea: "cost"
          }}
        >
          <CommonComponents.LegendItem
            sx={{ width: "120px" }}
            LabelProps={{ sx: { fontSize: "13px" } }}
            CircleProps={{ sx: { width: "16px", height: "16px" } }}
            label={CoreUtils.Formatter.currency(accepted) as string}
            color={theme.palette.primary.main}
            variant="striped"
          />
          <CommonComponents.LegendItem
            sx={{ width: "120px" }}
            LabelProps={{ sx: { fontSize: "13px" } }}
            CircleProps={{ sx: { width: "16px", height: "16px" } }}
            label={CoreUtils.Formatter.currency(allocated) as string}
            color={"#CB5800"}
          />
          <CommonComponents.LegendItem
            sx={{ width: "120px" }}
            LabelProps={{ sx: { fontSize: "13px" } }}
            CircleProps={{ sx: { width: "16px", height: "16px" } }}
            label={CoreUtils.Formatter.currency(paid) as string}
            color={theme.palette.primary.main}
          />
        </Stack>
        <ChevronRightIcon
          sx={{
            gridArea: "icon",
            ml: 1.5,
            transform: `rotate(${getExpanded()}) ? "90deg" : "0"})`
          }}
        />
      </Box>
      <BudgetTrackingTaskExpansion
        show={getExpanded()}
        taskId={taskId}
      />
    </>
  )
}
export default BudgetTrackingTask;