import { CoreComponents } from "@build-buddy/core";
import { Box, Card, CardContent, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";
import CostsPieData from "./_models/CostsPieData";
import CostsPieCardSkeleton from "./CostsPieCardSkeleton";
import CostsPieChart from "./CostsPieChart";
import CostsPieLegend, { CostsPieLegendProps } from "./CostsPieLegend";

interface CostsPieChartProps {
  data?: CostsPieData;
  hasBoq?: boolean;
  isLoading?: boolean;
  CostsPieLegendProps?: Partial<CostsPieLegendProps>;
  sx?: SxProps<Theme>;
}
const CostsPie = (props: CostsPieChartProps) => {
  const colours: any = {
    paid: "#FF6F00",
    committed: "#FFBA85",
    due: "#FD8E39",
    allocated: "#CB5800",
    remaining: "#E3E6E8"
  };

  const {
    CostsPieLegendProps,
    sx,
    // defaults
    data = {
      paid: 0,
      committed: 0,
      due: 0,
      allocated: 0,
      balance: 0,
      forecast: 0,
    },
    hasBoq = false,
    isLoading = false,
  } = props;

  const sorted = () => {
    return {
      paid: data.paid,
      committed: data.committed,
      due: data.due,
      allocated: data.allocated,
      ...(hasBoq && { remaining: data.balance })
    }
  };

  const getTotal = () => {
    return hasBoq
      ? data.forecast
      : Object.entries(data)
        .filter(([key, val]) => key !== "forecast")
        .reduce((acc, value) => acc + value[1], 0);
  }

  const chart = useMemo(() => {
    // if totals = 0 use a blank chart
    if (!getTotal()) return [{ key: "blank", value: 1, color: "#E3E6E8" }];

    // otherwise transform the data into a chart object
    return Object.entries(sorted()).map(([key, value]) => ({
      key,
      value,
      color: colours[key],
    }));
  }, [data, hasBoq])

  const legend = useMemo(() => {
    return Object.entries(sorted()).map(([key, value]) => ({
      key,
      value,
      color: colours[key as keyof typeof data],
    }));
  }, [data, hasBoq])


  return (
    <CoreComponents.PreContent
      isLoading={Boolean(isLoading)}
      loader={<CostsPieCardSkeleton />}
      isEmpty={false}
    >
      <Card
        sx={{ ...sx }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "160px",
                  sm: "200px",
                  xl: "250px"
                },
                mr: {
                  xs: 1,
                  sm: 3,
                  md: 5,
                  lg: 2,
                  xl: 5,
                }
              }}
            >
              <CostsPieChart
                data={chart}
                hasFinancials={hasBoq}
                total={getTotal()}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <CostsPieLegend
                data={legend}
                {...CostsPieLegendProps}
              />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </CoreComponents.PreContent>
  )
}
export default CostsPie;